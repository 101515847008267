import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const AbTestModel = types.model('AbTest').props({
  test_name: types.maybeNull(types.string),
  case_name: types.maybeNull(types.string),
});

type AbTestType = Instance<typeof AbTestModel>;
export interface AbTest extends AbTestType {}
type AbTestSnapshotType = SnapshotOut<typeof AbTestModel>;
export interface AbTestSnapshot extends AbTestSnapshotType {}
