import styles from './loading-indicator.module.scss';

const LoadingIndicator = () => (
  <span className={styles.container} role="status">
    <span className={styles.dot} />
    <span className={styles.dot} />
    <span className={styles.dot} />
  </span>
);

export default LoadingIndicator;
