import { SubscriptionPlanSnapshot } from '../models/subscription-plan/subscription-plan';
import { get, post } from './api';

export const ENDPOINT_MICROSERVICE = process.env
  .NEXT_PUBLIC_API_MICROSERVICE_ENDPOINT as string;

const responseToSubscriptionPlan = (
  response: any,
): SubscriptionPlanSnapshot => ({
  id: response['id'],
  name: response['name'],
  currency: response['currency'] == null ? 'USD' : response['currency'],
  priceAfterIntroOffer:
    response['price_after_intro_offer'] == null
      ? response['recurring_price']
      : response['price_after_intro_offer'],
  billingPeriod: response['billing_period'],
  billingPeriodUnit: response['billing_period_unit'],
  trialPeriodDays:
    response['trial_period_days'] == null
      ? response['trial_days']
      : response['trial_period_days'],
  specialOffer: response['special_offer'],
  discountPercentage: response['discount_percentage'],
  displayPrice: response['display_price'],
  fractionalPrice: +response['fractional_price'],
  fractionalPriceDiscount: +response['fractional_price_discount'],
  fullPrice: +response['full_price'],
  fullPriceDiscount: +response['full_price_discount'],
  initialPrice:
    response['initial_price'] == null ? undefined : +response['initial_price'],
  group: response['group'],
  description: response['description'],
  merchantAccountId: response['merchant_account_id'] ?? undefined,
  order: response['order'],
  paddlePlanId:
    response['paddle_plan_id'] == null
      ? undefined
      : +response['paddle_plan_id'],
  paddleIternalName:
    response['paddle_internal_name'] == null
      ? undefined
      : response['paddle_internal_name'],
  paypalPlanId: response['paypal_plan_id'] ?? undefined,
  stripePriceId: response['stripe_price_id'] ?? undefined,
  nuveiPlanId:
    response['nuvei_plan_id'] == null ? undefined : +response['nuvei_plan_id'],
  braintreePlanId:
    response['braintree_plan_id'] == null
      ? undefined
      : response['braintree_plan_id'],
  hideDescription:
    response['hide_description'] == null
      ? undefined
      : response['hide_description'],
  gift: response['gift'] == null ? undefined : response['gift'],
  preselected:
    response['preselected'] == null ? undefined : response['preselected'],
});

export const fetchSubscriptionPlans = async ({
  abortSignal,
  group,
}: {
  abortSignal?: AbortSignal;
  group?: string;
}): Promise<SubscriptionPlanSnapshot[]> => {
  const responseObject = await get({
    endpoint: ENDPOINT_MICROSERVICE,
    path: 'subscription_plans',
    abortSignal,
    params: { group: group ?? '' },
  });
  return responseObject.map((planResponseObject: any) =>
    responseToSubscriptionPlan(planResponseObject),
  );
};

export const generateBraintreeClientToken = async ({
  customerEmail,
  customerId,
  abortSignal,
}: {
  customerEmail: string;
  customerId: string;
  abortSignal?: AbortSignal;
}): Promise<void> =>
  await post({
    endpoint: ENDPOINT_MICROSERVICE,
    path: 'braintree_generate_client_token',
    body: {
      user_id: customerId,
      email: customerEmail,
    },
    abortSignal,
  });

export const createBraintreeSubscription = async ({
  customerId,
  customerToken,
  planId,
  paymentMethodNonce,
  paymentMethodVaulted = true,
  abortSignal,
}: {
  customerId: string;
  customerToken: string;
  planId: string;
  paymentMethodNonce: string;
  paymentMethodVaulted?: boolean;
  abortSignal?: AbortSignal;
}): Promise<void> =>
  await post({
    endpoint: ENDPOINT_MICROSERVICE,
    path: 'braintree_create_subscription',
    body: {
      user_id: customerId,
      token: customerToken,
      plan_id: planId,
      payment_method_nonce: paymentMethodNonce,
      payment_method_vaulted: paymentMethodVaulted,
    },
    abortSignal,
  });
