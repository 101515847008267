import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export enum BillingPeriodUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const SubscriptionPlanModel = types
  .model('SubscriptionPlan')
  .props({
    id: types.identifier,
    name: types.string,
    currency: types.string,
    priceAfterIntroOffer: types.string,
    billingPeriod: types.number,
    billingPeriodUnit: types.enumeration<BillingPeriodUnit>(
      Object.values(BillingPeriodUnit),
    ),
    trialPeriodDays: types.number,
    specialOffer: types.maybe(types.boolean),
    discountPercentage: types.number,
    merchantAccountId: types.maybe(types.string),
    order: types.number,
    displayPrice: types.string,
    fractionalPrice: types.number,
    fractionalPriceDiscount: types.number,
    initialPrice: types.maybe(types.number),
    fullPrice: types.number,
    fullPriceDiscount: types.number,
    group: types.string,
    description: types.string,
    paddlePlanId: types.maybe(types.number),
    paypalPlanId: types.maybe(types.string),
    paddleIternalName: types.maybe(types.string),
    stripePriceId: types.maybe(types.string),
    nuveiPlanId: types.maybe(types.number),
    braintreePlanId: types.maybe(types.string),
    hideDescription: types.maybe(types.boolean),
    gift: types.maybe(types.boolean),
    preselected: types.maybe(types.boolean),
  })
  .views((self) => ({
    get hasTrial() {
      return self.trialPeriodDays > 0;
    },
  }))
  .views((self) => ({
    get immediatePrice() {
      return self.hasTrial ? 0 : self.fullPriceDiscount;
    },
  }));

type SubscriptionPlanType = Instance<typeof SubscriptionPlanModel>;

export interface SubscriptionPlan extends SubscriptionPlanType {}

type SubscriptionPlanSnapshotType = SnapshotOut<typeof SubscriptionPlanModel>;

export interface SubscriptionPlanSnapshot
  extends SubscriptionPlanSnapshotType {}
