export const ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT as string;
export const ANALYTICS_ENDPOINT = process.env
  .NEXT_PUBLIC_API_ANALYTICS_ENDPOINT as string;

export class RequestError extends Error {
  name = 'RequestError';

  constructor(
    public statusCode: number,
    public statusText: string,
  ) {
    super(`${statusCode} ${statusText}`);
  }
}

export const request = async (
  endpoint: string,
  path: string,
  options: RequestInit,
) => {
  const url = new URL(path, endpoint).href;

  const response = await fetch(url, {
    credentials: 'include',
    ...options,
  });
  if (!response.ok) {
    throw new RequestError(response.status, response.statusText);
  }

  if (response.headers.get('Content-Type') === 'application/json') {
    return await response.json();
  }
  return undefined;
};

export const get = async ({
  endpoint = ENDPOINT,
  path,
  params,
  headers,
  abortSignal,
}: {
  endpoint?: string;
  path: string;
  params?: Record<string, string>;
  headers?: HeadersInit;
  abortSignal?: AbortSignal;
}) =>
  await request(
    endpoint,
    params ? `${path}?${new URLSearchParams(params)}` : path,
    {
      headers: headers,
      signal: abortSignal,
    },
  );

export const post = async ({
  endpoint = ENDPOINT,
  path,
  body,
  token,
  abortSignal,
}: {
  endpoint?: string;
  path: string;
  body: Record<string, any>;
  token?: string;
  abortSignal?: AbortSignal;
}) => {
  const headers = token
    ? new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      })
    : new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      });
  return await request(endpoint, path, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers,
    signal: abortSignal,
  });
};
