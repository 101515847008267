import { Instance, applySnapshot, cast, types } from 'mobx-state-tree';
import { flow } from 'mobx-state-tree';

import * as analytics from '@features/Analytics/api/analytics';
import * as gtag from '@features/Analytics/services/gtag';

import { AbTestModel, AbTestSnapshot } from '../ab-test/ab-test';

export const AnalyticsStoreModel = types
  .model('AnalyticsStore')
  .props({
    segment: types.maybe(types.string),
    abTests: types.maybe(types.array(AbTestModel)),
  })
  .actions((self) => ({
    saveAbTest: (
      segmentName: string | undefined,
      tests: Array<AbTestSnapshot> | undefined,
    ) => {
      self.segment = segmentName;
      self.abTests = cast(tests);
    },
  }))
  .actions((self) => ({
    getAbTests: flow(function* ({
      variant,
      utm_source,
      utm_campaign,
      region,
      abortSignal,
    }: {
      variant: string | undefined;
      utm_source: string | undefined;
      utm_campaign: string | undefined;
      region: string | undefined;
      abortSignal?: AbortSignal;
    }) {
      try {
        const responseObject = yield analytics.getAbTests({
          variant,
          utm_source,
          utm_campaign,
          region,
          abortSignal,
        });
        if (Object.keys(responseObject).length === 0) {
          self.saveAbTest('default', undefined);
          gtag.googleAnalyticsEvent({
            eventName: 'segment_name',
            properties: { name: 'default' },
          });
        } else {
          self.saveAbTest(responseObject.segment_name, responseObject.tests);
          gtag.googleAnalyticsEvent({
            eventName: 'segment_name',
            properties: { name: String(responseObject.segment_name) },
          });
          for (const test of responseObject.tests) {
            gtag.googleAnalyticsEvent({
              eventName: 'ab_test',
              properties: {
                name: String(test.test_name),
                group: String(test.case_name),
              },
            });
          }
        }
      } catch (error) {
        self.saveAbTest(undefined, undefined);
        gtag.googleAnalyticsEvent({
          eventName: 'segment_name',
          properties: { name: 'errorSegment' },
        });
        console.error(error);
      }
    }),
  }))
  .views((self) => ({
    getCaseNameByTestName: (testName: string) =>
      self.abTests?.find((test) => test.test_name === testName)?.case_name,
  }))
  .actions((self) => ({
    reset: () => {
      applySnapshot(self, {});
    },
  }));

type AnalyticsStoreType = Instance<typeof AnalyticsStoreModel>;

export interface AnalyticsStore extends AnalyticsStoreType {}
