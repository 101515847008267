import { UserSnapshot } from '../models/user/user';
import { RequestError, get, post } from './api';

const responseToUser = (response: any): UserSnapshot => ({
  id: response['id'],
  email: response['email'],
  firstName: response['first_name'],
  birthday: response['birthday'],
  astrologicalSign: response['astrological-sign'],
  token: response['token'],
  accessCode: response['access_code'],
  paidSubscriber: response['paid_subscriber'],
});

export const fetchUser = async ({
  token,
  abortSignal,
}: {
  token?: string;
  abortSignal?: AbortSignal;
}): Promise<UserSnapshot | null> => {
  const headers = token
    ? new Headers({ Authorization: `Token ${token}` })
    : undefined;
  try {
    return responseToUser(
      await get({
        path: 'user',
        headers: headers,
        abortSignal: abortSignal,
      }),
    );
  } catch (error) {
    if (
      error instanceof RequestError &&
      (error.statusCode === 403 || error.statusCode === 401)
    ) {
      return null;
    }
    throw error;
  }
};

export const register = async ({
  email,
  birthday,
  firstname,
  variant,
  abortSignal,
}: {
  email: string;
  birthday: string;
  firstname?: string;
  variant?: string | undefined;
  abortSignal?: AbortSignal;
}): Promise<UserSnapshot> =>
  responseToUser(
    await post({
      path: 'registration',
      body: {
        email: email,
        birthday: birthday,
        first_name: firstname,
        is_web_subscriber: true,
        variant: variant,
      },
      abortSignal,
    }),
  );

export const addUserBirthData = async ({
  timeOfBirth,
  latitude,
  longitude,
  firstName,
  birthPlace,
  birthday,
  token,
  abortSignal,
}: {
  timeOfBirth?: string;
  latitude?: number;
  longitude?: number;
  birthPlace?: string;
  birthday: string;
  firstName?: string;
  token: string;
  abortSignal?: AbortSignal;
}) =>
  await post({
    path: 'add_user_birth_data',
    body: {
      time_of_birth: timeOfBirth,
      latitude: latitude,
      longitude: longitude,
      first_name: firstName,
      birth_place: birthPlace,
      birthday: birthday,
    },
    token: token,
    abortSignal,
  });

export const addPartnerBirthData = async ({
  timeOfBirth,
  latitude,
  longitude,
  birthPlace,
  birthday,
  astrologicalSign,
  token,
  abortSignal,
}: {
  timeOfBirth?: string;
  latitude?: number;
  longitude?: number;
  birthPlace?: string;
  birthday?: string;
  astrologicalSign?: string;
  token: string;
  abortSignal?: AbortSignal;
}) =>
  await post({
    path: 'partner',
    body: {
      birth_time: timeOfBirth,
      latitude: latitude,
      longitude: longitude,
      birth_place: birthPlace,
      birth_date: birthday,
      astrological_sigh: astrologicalSign,
    },
    token: token,
    abortSignal,
  });

export const logOut = async (token: string) => {
  try {
    await post({
      path: 'logout',
      body: {},
      token: token,
    });
  } catch (error) {
    if (
      error instanceof RequestError &&
      (error.statusCode === 403 || error.statusCode === 401)
    ) {
      return;
    }
    throw error;
  }
};

export const getSafariAuthToken = async (token: string): Promise<string> => {
  const responseObject = await post({
    path: 'get_safari_auth_token',
    body: {},
    token: token,
  });
  return responseObject['temp_token'];
};

export const loginBySafariAuthToken = async ({
  safariAuthToken,
}: {
  safariAuthToken: string;
}) =>
  responseToUser(
    await post({
      path: 'login_with_safari_auth_token',
      body: {
        temp_token: safariAuthToken,
      },
    }),
  );

export const loginWithEmailToken = async ({
  emailToken,
  abortSignal,
}: {
  emailToken: string;
  abortSignal?: AbortSignal;
}): Promise<UserSnapshot> =>
  responseToUser(
    await post({
      path: 'login_with_email_token',
      body: { email_token: emailToken },
      abortSignal,
    }),
  );
