import { ComponentProps, ReactNode } from 'react';

import Button from '../../../common/button/button';
import Alert from '../../../common/modal/alert/alert';

const AlertWithCloseButton = ({
  closeButtonText = 'OK',
  footer,
  buttonVariant,
  ...props
}: { closeButtonText?: ReactNode } & ComponentProps<typeof Alert>) => {
  const { onHide } = props;

  return (
    <Alert
      footer={
        <>
          {footer}
          <Button variant={buttonVariant} type="button" onClick={onHide}>
            {closeButtonText}
          </Button>
        </>
      }
      {...props}
    />
  );
};

export default AlertWithCloseButton;
