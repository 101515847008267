import classNames from 'classnames';
import ImageTag from 'next/image';
import type { ImageProps } from 'next/image';

import styles from './Image.module.scss';

export type TpImageProps = ImageProps & {
  className?: string;
};

export const imgSource =
  process.env.NEXT_PUBLIC_ENV === 'development'
    ? 'https://d3n9zj8450sldb.cloudfront.net/images/'
    : 'https://di7v760w9ubxl.cloudfront.net/images/';
// export const imgSource = 'images/';

const customLoader = ({ src }: { src: string }) => `${imgSource}${src}`;

export const Image = (props: TpImageProps) => {
  const { src, className, ...otherProps } = props;

  return (
    <span className={classNames(styles.container, className)}>
      <ImageTag src={src} loader={customLoader} {...otherProps} />
    </span>
  );
};
