export const retry = async ({
  conditionCheckFunction,
  actionFunction,
  maxRetries = 5,
  retryDelay,
}: {
  conditionCheckFunction: () => Promise<boolean>;
  actionFunction: () => Promise<void>;
  maxRetries?: number;
  retryDelay: number;
}) => {
  let retries = 0;

  while (retries < maxRetries) {
    if (await conditionCheckFunction()) {
      await actionFunction();
      return;
    }
    retries++;
    await new Promise((resolve) => setTimeout(resolve, retryDelay));
  }

  console.log('Max retries exceeded', actionFunction);
};
