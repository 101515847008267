import * as Sentry from '@sentry/react';
import useEventListener from '@use-it/event-listener';
import { ComponentProps, ReactNode, useState } from 'react';

import { Image } from '../Image';
import ContactUsLink from '../contact-us-link/contact-us-link';
import AlertWithCloseButton from '../modal/alert-with-close-button/alert-with-close-button';
import Alert from '../modal/alert/alert';

type TpErrorBoundaryProps = {
  children: ReactNode;
};

const ErrorAlert = ({
  AlertComponent = Alert,
  show = true,
  ...props
}: { AlertComponent?: typeof Alert } & ComponentProps<typeof Alert>) => (
  <AlertComponent
    show={show}
    image={
      <Image src="technical-problems.webp" width={160} height={120} alt="" />
    }
    title={<>Oops! Something&nbsp;went&nbsp;wrong</>}
    description={
      <>
        We’re trying to fix it up.
        <br />
        Please reload the page or try again later. If the issue persists, feel
        free to <ContactUsLink />.
      </>
    }
    {...props}
  />
);

const myFallback = <ErrorAlert />;

export const ErrorBoundary = (props: TpErrorBoundaryProps) => {
  const { children } = props;
  const [caughtUnhandledRejection, setCaughtUnhandledRejection] =
    useState(false);
  useEventListener(
    'unhandledrejection',
    ({ reason }: PromiseRejectionEvent) => {
      if (
        (reason instanceof Error && reason.name === 'AbortError') ||
        (reason instanceof Error && reason.name === 'NotAllowedError')
      ) {
        return;
      }
      console.log(reason);
      setCaughtUnhandledRejection(true);
      Sentry.captureException(reason);
    },
  );
  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <ErrorAlert
        AlertComponent={AlertWithCloseButton}
        show={caughtUnhandledRejection}
        onHide={() => {
          setCaughtUnhandledRejection(false);
          window.location.reload();
        }}
      />
      {children}
    </Sentry.ErrorBoundary>
  );
};
