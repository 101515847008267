export const getDataFromSessionStorage = ({
  store,
  key,
}: {
  store: any;
  key: string;
}): string | null => {
  const storedDataString =
    typeof window !== 'undefined' && sessionStorage.getItem('root');

  try {
    const storedData = storedDataString ? JSON.parse(storedDataString) : null;
    return storedData?.[store]?.[key] ?? null;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
};
