import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { AnalyticsStoreModel } from '../analytics-store/analytics-store';
import { AuthStoreModel } from '../auth-store/auth-store';
import { PaymentsStoreModel } from '../payments-store/payments-store';
import { QuizStoreModel } from '../quiz-store/quiz-store';

/**
 * A RootStore model.
 */
export const RootStoreModel = types.model('RootStore').props({
  analyticsStore: types.optional(AnalyticsStoreModel, {}),
  quizStore: types.optional(QuizStoreModel, {}),
  authStore: types.optional(AuthStoreModel, {}),
  paymentsStore: types.optional(PaymentsStoreModel, {}),
});

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
