import Cookies from 'js-cookie';
import Script from 'next/script';

import { FACEBOOK_PIXEL_ID } from '../../constants';

const FbPixel = () => {
  const storedExternalId = Cookies.get('extId');
  const randomInt = Math.floor(Math.random() * 100_000_000);
  const generateId = () => {
    Cookies.set('extId', `${randomInt}`, { expires: 365 });
    return `${randomInt}`;
  };

  const externalId = storedExternalId || generateId();

  return (
    <Script id="facebook-pixel">
      {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq.disablePushState = true;
        fbq('init', ${FACEBOOK_PIXEL_ID}, {'extern_id': ${externalId}});
        fbq('track', 'PageView');`}
    </Script>
  );
};

export default FbPixel;
