import axios from 'axios';

import { ENDPOINT_MICROSERVICE } from 'src/constants/api';
import { isDevelopment } from 'src/utils';

import {
  ANALYTICS_ENDPOINT,
  ANALYTICS_ENDPOINT_V2,
} from '@features/Analytics/constants';

const axiosMicroservice = axios.create({
  baseURL: ENDPOINT_MICROSERVICE,
  timeout: 30_000,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosMicroservice.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/';
    }
  },
);

const axiosAnalytics = axios.create({
  baseURL: isDevelopment
    ? 'https://dev.analytics.mentalgrowth.app/api/v1/'
    : ANALYTICS_ENDPOINT_V2,
  timeout: 30_000,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosAnalytics.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/';
    }
  },
);

export { axiosMicroservice, axiosAnalytics };
