import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Button from 'src/components/common/button/button';
import { ALL_VARIANTS } from 'src/constants/variants';
import { getDataFromSessionStorage, groupBy } from 'src/utils';

import { useAnalyticsDebugger } from '@features/Analytics/hooks/useAnalytics';

import styles from './debug-ui.module.scss';

type TpSource = 'PT' | 'GA';

export const DebugUi = () => {
  const [activeTab, setActiveTab] = useState<TpSource>('GA');
  const [hasMounted, setHasMounted] = useState(false);
  const variantFromStorage =
    getDataFromSessionStorage({
      store: 'authStore',
      key: 'variant',
    }) ?? 'undefined';
  const [activeVariant, setActiveVariant] = useState(variantFromStorage);

  const { events, clearEvents, eventsNumberVisible, setEventsNumberVisible } =
    useAnalyticsDebugger();

  const toggleEventsNumber = useCallback(
    () => setEventsNumberVisible(!eventsNumberVisible),
    [eventsNumberVisible, setEventsNumberVisible],
  );

  const grouped = useMemo(() => groupBy(events, 'source'), [events]);

  const filteredByGroup = useMemo(
    () => grouped[activeTab],
    [activeTab, grouped],
  );

  const handleButtonClick = useCallback((value: TpSource) => {
    setActiveTab(value);
  }, []);

  const clearHandler = useCallback(() => {
    clearEvents();
  }, [clearEvents]);

  const handleVariantChange = useCallback((event: any) => {
    setActiveVariant(event.target.value);
  }, []);

  const ABTest = useCallback(() => {
    // @ts-ignore
    const abTestFromStorage: { case_name: string; test_name: string }[] =
      getDataFromSessionStorage({
        store: 'analyticsStore',
        key: 'abTests',
      }) ?? [];

    return abTestFromStorage.length > 0 ? (
      <div>
        {abTestFromStorage[0]?.case_name} {abTestFromStorage[0]?.test_name}
      </div>
    ) : null;
  }, []);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <div
      className={classNames(styles.host, {
        [styles.expanded]: eventsNumberVisible,
      })}>
      <div className={styles.row}>
        <Button size="sm" onClick={toggleEventsNumber}>
          Log
        </Button>
        <select
          className={styles.select}
          name="variant-select"
          id="select"
          onChange={handleVariantChange}
          defaultValue={variantFromStorage}>
          {ALL_VARIANTS.map((variant) => (
            <option key={variant.variant_name} value={variant.variant_name}>
              {variant.variant_name}
            </option>
          ))}
        </select>
        <Button
          size="sm"
          handleClick={() =>
            window.location.replace(`/quiz?reset=1&variant=${activeVariant}`)
          }>
          Reset
        </Button>
        {eventsNumberVisible && (
          <>
            <Button size="sm" onClick={clearHandler}>
              Clear
            </Button>
            {Object.keys(grouped).map((key) => (
              <Button
                key={key}
                size="sm"
                onClick={() => handleButtonClick(key as TpSource)}>
                {key}
              </Button>
            ))}
          </>
        )}
      </div>
      {hasMounted && <ABTest />}
      {eventsNumberVisible && (
        <div className={styles.list}>
          {filteredByGroup != null &&
            filteredByGroup.map((item: { date: string; name: string }) => (
              <div key={`${item.name}${item.date}`} className={styles.item}>
                <span>{JSON.stringify(item, null, 2)}</span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
