import Cookies from 'js-cookie';

//TODO: нужно подставить корректные названия событий из twitter кабины
export type TpTwitterEventId =
  | 'example1' //registration
  | 'example2' //checkout
  | 'example3'; //purchase

const externalId = Cookies.get('extId');
export const twitterEvent = (
  eventId: TpTwitterEventId,
  options: { [key: string]: any },
) => {
  // @ts-ignore
  window?.twq?.('event', eventId, { conversion_id: externalId, ...options });
};
