import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { isDevelopment } from 'src/utils';

import {
  Clarity,
  GoogleAnalytics,
  PinterestPixel,
  TikTokPixel,
  TwitterPixel,
} from '@features/Analytics';
import { DebugUi } from '@features/Analytics/components/DebugUi';
import { useShouldInit } from '@features/Analytics/hooks';

import FbPixel from '../FacebookPixel';

export const AnalyticsInject = observer(() => {
  const {
    authStore: { variant },
  } = useStores();

  const {
    shouldPixelInit,
    shouldPTInit,
    shouldTwitterInit,
    shouldTikTokInit,
    shouldGoogleAnalyticsInit,
  } = useShouldInit(variant);

  return (
    <>
      {shouldPixelInit && <FbPixel />}
      {shouldGoogleAnalyticsInit && <GoogleAnalytics />}
      {shouldPTInit && <PinterestPixel />}
      {shouldTwitterInit && <TwitterPixel />}
      {shouldTikTokInit && <TikTokPixel />}
      {!isDevelopment && <Clarity />}
      {isDevelopment && <DebugUi />}
    </>
  );
});
