import { onSnapshot } from 'mobx-state-tree';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import {
  RootStore,
  RootStoreModel,
  RootStoreSnapshot,
} from 'src/models/root-store/root-store';

const ROOT_STATE_STORAGE_KEY = 'root';

/**
 * Create a context we can use to
 * - Provide access to our stores from our root component
 * - Consume stores in our screens (or other components, though it's
 *   preferable to just connect screens)
 */
const RootStoreContext = createContext({} as RootStore);

/**
 * The provider our root component will use to expose the root store
 */
export const RootStoreProvider = ({ children }: PropsWithChildren<{}>) => {
  const rootStore = useMemo(() => {
    if (typeof sessionStorage !== 'undefined') {
      const data = sessionStorage.getItem(ROOT_STATE_STORAGE_KEY);
      if (data != null) {
        try {
          return RootStoreModel.create(JSON.parse(data));
        } catch (error) {
          console.warn(
            'Failed to apply root store snapshot from sessionStorage',
            error,
          );
        }
      }
    }

    return RootStoreModel.create();
  }, []);

  useEffect(
    () =>
      onSnapshot(rootStore, (snapshot: RootStoreSnapshot) => {
        if (snapshot.authStore.user) {
          // Cannot use a stale user because of routing being based on
          // whether the subscription has been purchased
          snapshot = {
            ...snapshot,
            authStore: { ...snapshot.authStore, user: undefined },
          };
        }
        sessionStorage.setItem(
          ROOT_STATE_STORAGE_KEY,
          JSON.stringify(snapshot),
        );
      }),
    [rootStore],
  );

  return (
    <RootStoreContext.Provider value={rootStore}>
      {children}
    </RootStoreContext.Provider>
  );
};

/**
 * A hook that screens can use to gain access to our stores, with
 * `const { someStore, someOtherStore } = useStores()`,
 * or less likely: `const rootStore = useStores()`
 */
export const useStores = () => useContext(RootStoreContext);
