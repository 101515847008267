import dayjs from 'dayjs';
import { Instance, applySnapshot, types } from 'mobx-state-tree';

import { SIGNS } from 'src/constants/astrology-signs';
import { QuizStep } from 'src/constants/quiz';

export const QuizStoreModel = types
  .model('QuizStore')
  .props({
    country: types.maybe(types.string),
    countryCode: types.maybe(types.string),
    welcome: types.maybe(types.number),
    facingRepeating: types.maybe(types.number),
    aspect: types.maybe(types.number),
    desiredRelationship: types.maybe(types.number),
    previousRelationship: types.maybe(types.number),
    toxicRelationship: types.maybe(types.number),
    inLove: types.maybe(types.number),
    romanticFeelings: types.maybe(types.number),
    karmicLessons: types.maybe(types.number),
    purpose: types.maybe(types.number),
    jobLoving: types.maybe(types.number),
    financialGoal: types.maybe(types.number),
    lifeAspects: types.maybe(types.number),
    workOut: types.maybe(types.number),
    spiritualGoal: types.maybe(types.number),
    moonPower: types.maybe(types.frozen(types.array(types.string))),
    mood: types.maybe(types.number),
    life: types.maybe(types.number),
    criticsSensitive: types.maybe(types.number),
    hotTempred: types.maybe(types.number),
    moodSwings: types.maybe(types.number),
    emotions: types.maybe(types.number),
    inspires: types.maybe(types.frozen(types.array(types.string))),
    others: types.maybe(types.frozen(types.array(types.string))),
    gender: types.maybe(types.number),
    name: types.maybe(types.string),
    dateOfBirth: types.maybe(types.Date),
    hometown: types.maybe(types.string),
    userLatitude: types.maybe(types.number),
    userLongitude: types.maybe(types.number),
    birthtimeKnowledge: types.maybe(types.number),
    birthtime: types.maybe(types.string),
    relationship: types.maybe(types.number),
    sex: types.maybe(types.number),
    comfortable: types.maybe(types.number),
    goodEnough: types.maybe(types.number),
    relationshipGoal: types.maybe(types.number),
    longTerm: types.maybe(types.number),
    partnerBirthtimeKnowledge: types.maybe(types.number),
    partnerBirthtime: types.maybe(types.string),
    partnerHometown: types.maybe(types.string),
    partnerLatitude: types.maybe(types.number),
    partnerLongitude: types.maybe(types.number),
    discussingProblems: types.maybe(types.number),
    affectRelationship: types.maybe(types.number),
    sexRelationship: types.maybe(types.number),
    partnerAstrologySign: types.maybe(types.Date),
  })
  .views((self) => ({
    getSingleOptionSelectStepValue: (step: QuizStep) => {
      switch (step) {
        case QuizStep.FACING_REPEATING: {
          return self.facingRepeating;
        }
        case QuizStep.ASPECT: {
          return self.aspect;
        }
        case QuizStep.DESIRED_RELATIONSHIP: {
          return self.desiredRelationship;
        }
        case QuizStep.PREVIOUS_RELATIONSHIP: {
          return self.previousRelationship;
        }
        case QuizStep.TOXIC_RELATIONSHIP: {
          return self.toxicRelationship;
        }
        case QuizStep.IN_LOVE: {
          return self.inLove;
        }
        case QuizStep.ROMANTIC_FEELINGS: {
          return self.romanticFeelings;
        }
        case QuizStep.KARMIC_LESSONS: {
          return self.karmicLessons;
        }
        case QuizStep.PURPOSE: {
          return self.purpose;
        }
        case QuizStep.JOB_LOVING: {
          return self.jobLoving;
        }
        case QuizStep.FINANCIAL_GOAL: {
          return self.financialGoal;
        }
        case QuizStep.LIFE_ASPECTS: {
          return self.lifeAspects;
        }
        case QuizStep.WORK_OUT: {
          return self.workOut;
        }
        case QuizStep.SPIRITUAL_GOAL: {
          return self.spiritualGoal;
        }
        case QuizStep.MOOD: {
          return self.mood;
        }
        case QuizStep.EMOTIONS: {
          return self.emotions;
        }
        case QuizStep.LIFE: {
          return self.life;
        }
        case QuizStep.CRITICS_SENSITIVE: {
          return self.criticsSensitive;
        }
        case QuizStep.HOT_TEMPRED: {
          return self.hotTempred;
        }
        case QuizStep.MOOD_SWINGS: {
          return self.moodSwings;
        }
        case QuizStep.GENDER: {
          return self.gender;
        }
        case QuizStep.BIRTH_TIME_KNOWLEDGE: {
          return self.birthtimeKnowledge;
        }
        case QuizStep.RELATIONSHIP: {
          return self.relationship;
        }
        case QuizStep.SEX: {
          return self.sex;
        }
        case QuizStep.COMFORTABLE: {
          return self.comfortable;
        }
        case QuizStep.GOOD_ENOUGH: {
          return self.goodEnough;
        }
        case QuizStep.RELATIONSHIP_GOAL: {
          return self.relationshipGoal;
        }
        case QuizStep.LONG_TERM: {
          return self.longTerm;
        }
        case QuizStep.PARTNER_BIRTH_TIME_KNOWLEDGE: {
          return self.partnerBirthtimeKnowledge;
        }
        case QuizStep.DISCUSSING_PROBLEMS: {
          return self.discussingProblems;
        }
        case QuizStep.SEX_RELATIONSHIP: {
          return self.sexRelationship;
        }
        case QuizStep.AFFECT_RELATIONSHIP: {
          return self.affectRelationship;
        }
        default: {
          throw new Error('The step is not of type SINGLE_OPTION_SELECT');
        }
      }
    },
    get welcomeStepCompleted() {
      return self.welcome != null;
    },
    get facingRepeatingStepCompleted() {
      return self.facingRepeating != null;
    },
    get aspectStepCompleted() {
      return self.aspect != null;
    },
    get desiredRelationshipStepCompleted() {
      return self.desiredRelationship != null;
    },
    get previousRelationshipStepCompleted() {
      return self.previousRelationship != null;
    },
    get toxicRelationshipStepCompleted() {
      return self.toxicRelationship != null;
    },
    get inLoveStepCompleted() {
      return self.inLove != null;
    },
    get romanticFeelingsStepCompleted() {
      return self.romanticFeelings != null;
    },
    get karmicLessonsStepCompleted() {
      return self.karmicLessons != null;
    },
    get purposeStepCompleted() {
      return self.purpose != null;
    },
    get jobLovingStepCompleted() {
      return self.jobLoving != null;
    },
    get financialGoalStepCompleted() {
      return self.financialGoal != null;
    },
    get lifeAspectsStepCompleted() {
      return self.lifeAspects != null;
    },
    get workOutStepCompleted() {
      return self.workOut != null;
    },
    get spiritualGoalStepCompleted() {
      return self.spiritualGoal != null;
    },
    get moonPowerStepCompleted() {
      return self.moonPower != null;
    },
    get moodStepCompleted() {
      return self.mood != null;
    },
    get lifeStepCompleted() {
      return self.life != null;
    },
    get criticsSensitiveStepCompleted() {
      return self.criticsSensitive != null;
    },
    get hotTempredStepCompleted() {
      return self.hotTempred != null;
    },
    get moodSwingsStepCompleted() {
      return self.moodSwings != null;
    },
    get emotionsStepCompleted() {
      return self.emotions != null;
    },
    get inspiresStepCompleted() {
      return self.inspires != null;
    },
    get othersStepCompleted() {
      return self.others != null;
    },
    get genderStepCompleted() {
      return self.gender != null;
    },
    get nameStepCompleted() {
      return self.name != null;
    },
    get dateOfBirthStepCompleted() {
      return self.dateOfBirth != null;
    },
    get hometownStepCompleted() {
      return (
        self.hometown != null ||
        self.userLatitude != null ||
        self.userLongitude != null
      );
    },
    get birthtimeKnowledgeStepCompleted() {
      return self.birthtimeKnowledge != null;
    },
    get birthtimeStepCompleted() {
      return self.birthtime != null;
    },
    get relationshipStepCompleted() {
      return self.relationship != null;
    },
    get sexStepCompleted() {
      return self.sex != null;
    },
    get comfortableStepCompleted() {
      return self.comfortable != null;
    },
    get goodEnoughStepCompleted() {
      return self.goodEnough != null;
    },
    get relationshipGoalStepCompleted() {
      return self.relationshipGoal != null;
    },
    get longTermStepCompleted() {
      return self.longTerm != null;
    },
    get partnerBirthtimeKnowledgeStepCompleted() {
      return self.partnerBirthtimeKnowledge != null;
    },
    get partnerBirthtimeStepCompleted() {
      return self.partnerBirthtime != null;
    },
    get partnerHometownStepCompleted() {
      return (
        self.partnerHometown != null ||
        self.partnerLatitude != null ||
        self.partnerLongitude != null
      );
    },
    get discussingProblemsStepCompleted() {
      return self.discussingProblems != null;
    },
    get affectRelationshipStepCompleted() {
      return self.affectRelationship != null;
    },
    get sexRelationshipStepCompleted() {
      return self.sexRelationship != null;
    },
    get partnerAstrologySignStepCompleted() {
      return self.partnerAstrologySign != null;
    },
  }))
  .views((self) => ({
    get allQuizStepsCompleted() {
      return (
        self.welcomeStepCompleted &&
        self.facingRepeatingStepCompleted &&
        self.aspectStepCompleted &&
        self.desiredRelationshipStepCompleted &&
        self.previousRelationshipStepCompleted &&
        self.toxicRelationshipStepCompleted &&
        self.inLoveStepCompleted &&
        self.romanticFeelingsStepCompleted &&
        self.karmicLessonsStepCompleted &&
        self.purposeStepCompleted &&
        self.jobLovingStepCompleted &&
        self.financialGoalStepCompleted &&
        self.lifeAspectsStepCompleted &&
        self.workOutStepCompleted &&
        self.spiritualGoalStepCompleted &&
        self.moonPowerStepCompleted &&
        self.moodStepCompleted &&
        self.lifeStepCompleted &&
        self.criticsSensitiveStepCompleted &&
        self.hotTempredStepCompleted &&
        self.moodSwingsStepCompleted &&
        self.emotionsStepCompleted &&
        self.relationshipStepCompleted &&
        self.inspiresStepCompleted &&
        self.othersStepCompleted &&
        self.genderStepCompleted &&
        self.nameStepCompleted &&
        self.dateOfBirthStepCompleted &&
        self.hometownStepCompleted &&
        self.birthtimeKnowledgeStepCompleted &&
        self.birthtimeStepCompleted &&
        self.sexStepCompleted &&
        self.comfortableStepCompleted &&
        self.goodEnoughStepCompleted &&
        self.relationshipGoalStepCompleted &&
        self.longTermStepCompleted &&
        self.partnerBirthtimeKnowledgeStepCompleted &&
        self.partnerBirthtimeStepCompleted &&
        self.partnerHometownStepCompleted &&
        self.discussingProblemsStepCompleted &&
        self.affectRelationshipStepCompleted &&
        self.sexRelationshipStepCompleted
      );
    },
  }))
  .actions((self) => ({
    setCountry: (value: string) => {
      self.country = value;
    },
    setCountryCode: (value: string) => {
      self.countryCode = value;
    },
    setWelcome: (value: number) => {
      self.welcome = value;
    },
    setFacingRepeating: (value: number) => {
      self.facingRepeating = value;
    },
    setAspect: (value: number) => {
      self.aspect = value;
    },
    setDesiredRelationship: (value: number) => {
      self.desiredRelationship = value;
    },
    setPreviousRelationship: (value: number) => {
      self.previousRelationship = value;
    },
    setToxicRelationship: (value: number) => {
      self.toxicRelationship = value;
    },
    setInLove: (value: number) => {
      self.inLove = value;
    },
    setRomanticFeelings: (value: number) => {
      self.romanticFeelings = value;
    },
    setKarmicLessons: (value: number) => {
      self.karmicLessons = value;
    },
    setPurpose: (value: number) => {
      self.purpose = value;
    },
    setJobLoving: (value: number) => {
      self.jobLoving = value;
    },
    setFinancialGoal: (value: number) => {
      self.financialGoal = value;
    },
    setLifeAspects: (value: number) => {
      self.lifeAspects = value;
    },
    setWorkOut: (value: number) => {
      self.workOut = value;
    },
    setSpiritualGoal: (value: number) => {
      self.spiritualGoal = value;
    },
    setMoonPower: (value?: readonly string[]) => {
      self.moonPower = value;
    },
    setMood: (value: number) => {
      self.mood = value;
    },
    setLife: (value: number) => {
      self.life = value;
    },
    setCriticsSensitive: (value: number) => {
      self.criticsSensitive = value;
    },
    setHotTempred: (value: number) => {
      self.hotTempred = value;
    },
    setMoodSwings: (value: number) => {
      self.moodSwings = value;
    },
    setEmotions: (value: number) => {
      self.emotions = value;
    },
    setRelationship: (value: number) => {
      self.relationship = value;
    },
    setInspires: (value?: readonly string[]) => {
      self.inspires = value;
    },
    setOthers: (value?: readonly string[]) => {
      self.others = value;
    },
    setGender: (value: number) => {
      self.gender = value;
    },
    setName: (value: string) => {
      self.name = value;
    },
    setDateOfBirth: (value: Date) => {
      self.dateOfBirth = value;
    },
    setHometown: (value: string, latitude: number, longitude: number) => {
      self.hometown = value;
      self.userLatitude = latitude;
      self.userLongitude = longitude;
    },
    setBirthtimeKnowledge: (value: number) => {
      self.birthtimeKnowledge = value;
    },
    setBirthtime: (value: string) => {
      self.birthtime = value;
    },
    setPartnerAstrologySign: (value: Date) => {
      self.partnerAstrologySign = value;
    },
    setSex: (value: number) => {
      self.sex = value;
    },
    setComfortable: (value: number) => {
      self.comfortable = value;
    },
    setGoodEnough: (value: number) => {
      self.goodEnough = value;
    },
    setRelationshipGoal: (value: number) => {
      self.relationshipGoal = value;
    },
    setLongTerm: (value: number) => {
      self.longTerm = value;
    },
    setPartnerBirthtimeKnowledge: (value: number) => {
      self.partnerBirthtimeKnowledge = value;
    },
    setPartnerBirthtime: (value: string) => {
      self.partnerBirthtime = value;
    },
    setPartnerHometown: (
      value: string,
      latitude: number,
      longitude: number,
    ) => {
      self.partnerHometown = value;
      self.partnerLatitude = latitude;
      self.partnerLongitude = longitude;
    },
    setDiscussingProblems: (value: number) => {
      self.discussingProblems = value;
    },
    setAffectRelationship: (value: number) => {
      self.affectRelationship = value;
    },
    setSexRelationship: (value: number) => {
      self.sexRelationship = value;
    },
    fillInAllSteps: () => {
      if (!self.welcomeStepCompleted) {
        self.welcome = 0;
      }
      if (!self.facingRepeatingStepCompleted) {
        self.facingRepeating = 0;
      }
      if (!self.aspectStepCompleted) {
        self.aspect = 0;
      }
      if (!self.desiredRelationshipStepCompleted) {
        self.desiredRelationship = 0;
      }
      if (!self.previousRelationshipStepCompleted) {
        self.previousRelationship = 0;
      }
      if (!self.toxicRelationshipStepCompleted) {
        self.toxicRelationship = 0;
      }
      if (!self.inLoveStepCompleted) {
        self.inLove = 0;
      }
      if (!self.romanticFeelingsStepCompleted) {
        self.romanticFeelings = 0;
      }
      if (!self.karmicLessonsStepCompleted) {
        self.karmicLessons = 0;
      }
      if (!self.purposeStepCompleted) {
        self.purpose = 0;
      }
      if (!self.jobLovingStepCompleted) {
        self.jobLoving = 0;
      }
      if (!self.financialGoalStepCompleted) {
        self.financialGoal = 0;
      }
      if (!self.lifeAspectsStepCompleted) {
        self.lifeAspects = 0;
      }
      if (!self.workOutStepCompleted) {
        self.workOut = 0;
      }
      if (!self.spiritualGoalStepCompleted) {
        self.spiritualGoal = 0;
      }
      if (!self.moonPowerStepCompleted) {
        self.moonPower = ['mood'];
      }
      if (!self.moodStepCompleted) {
        self.mood = 0;
      }
      if (!self.lifeStepCompleted) {
        self.life = 0;
      }
      if (!self.criticsSensitiveStepCompleted) {
        self.criticsSensitive = 0;
      }
      if (!self.hotTempredStepCompleted) {
        self.hotTempred = 0;
      }
      if (!self.moodSwingsStepCompleted) {
        self.moodSwings = 0;
      }
      if (!self.emotionsStepCompleted) {
        self.emotions = 0;
      }
      if (!self.relationshipStepCompleted) {
        self.relationship = 0;
      }
      if (!self.inspiresStepCompleted) {
        self.inspires = ['beautiful'];
      }
      if (!self.othersStepCompleted) {
        self.others = ['art'];
      }
      if (!self.genderStepCompleted) {
        self.gender = 0;
      }
      if (!self.name) {
        self.name = 'username';
      }
      if (!self.dateOfBirthStepCompleted) {
        self.dateOfBirth = dayjs().toDate();
      }
      if (!self.hometownStepCompleted) {
        self.hometown = 'New York City, NY, USA';
        self.userLatitude = 12.345_672;
        self.userLongitude = 12.345_672;
      }
      if (!self.birthtimeKnowledgeStepCompleted) {
        self.birthtimeKnowledge = 0;
      }
      if (!self.birthtimeStepCompleted) {
        self.birthtime = '00:00 am';
      }
      if (!self.partnerAstrologySignStepCompleted) {
        self.partnerAstrologySign = dayjs().toDate();
      }
      if (!self.sexStepCompleted) {
        self.sex = 0;
      }
      if (!self.comfortableStepCompleted) {
        self.comfortable = 0;
      }
      if (!self.goodEnoughStepCompleted) {
        self.goodEnough = 0;
      }
      if (!self.relationshipGoalStepCompleted) {
        self.relationshipGoal = 0;
      }
      if (!self.longTermStepCompleted) {
        self.longTerm = 0;
      }
      if (!self.partnerBirthtimeKnowledgeStepCompleted) {
        self.partnerBirthtimeKnowledge = 0;
      }
      if (!self.partnerBirthtimeStepCompleted) {
        self.partnerBirthtime = '00:00 am';
      }
      if (!self.partnerHometownStepCompleted) {
        self.partnerHometown = 'New York City, NY, USA';
        self.partnerLatitude = 12.345_672;
        self.partnerLongitude = 12.345_672;
      }
      if (!self.discussingProblemsStepCompleted) {
        self.discussingProblems = 0;
      }
      if (!self.affectRelationshipStepCompleted) {
        self.affectRelationship = 0;
      }
      if (!self.sexRelationshipStepCompleted) {
        self.sexRelationship = 0;
      }
    },
  }))
  .views((self) => ({
    getAstrologySing(dateOfBirth = self.dateOfBirth) {
      const month = dateOfBirth!.getMonth();
      const day = dateOfBirth!.getDate();

      if ((month == 0 && day <= 19) || (month == 11 && day >= 22)) {
        return SIGNS.Capricorn;
      } else if ((month == 0 && day >= 20) || (month == 1 && day <= 18)) {
        return SIGNS.Aquarius;
      } else if ((month == 1 && day >= 19) || (month == 2 && day <= 20)) {
        return SIGNS.Pisces;
      } else if ((month == 2 && day >= 21) || (month == 3 && day <= 19)) {
        return SIGNS.Aries;
      } else if ((month == 3 && day >= 20) || (month == 4 && day <= 20)) {
        return SIGNS.Taurus;
      } else if ((month == 4 && day >= 21) || (month == 5 && day <= 20)) {
        return SIGNS.Gemini;
      } else if ((month == 5 && day >= 21) || (month == 6 && day <= 22)) {
        return SIGNS.Cancer;
      } else if ((month == 6 && day >= 23) || (month == 7 && day <= 22)) {
        return SIGNS.Leo;
      } else if ((month == 7 && day >= 23) || (month == 8 && day <= 22)) {
        return SIGNS.Virgo;
      } else if ((month == 8 && day >= 23) || (month == 9 && day <= 22)) {
        return SIGNS.Libra;
      } else if ((month == 9 && day >= 23) || (month == 10 && day <= 21)) {
        return SIGNS.Scorpio;
      } else if ((month == 10 && day >= 22) || (month == 11 && day <= 21)) {
        return SIGNS.Sagittarius;
      } else {
        return SIGNS.Capricorn;
      }
    },
    getGender() {
      switch (self.gender) {
        case 0:
        case 2: {
          return 'f';
        }
        case 1: {
          return 'm';
        }
        default: {
          return undefined;
        }
      }
    },
  }))
  .actions((self) => ({
    reset: () => {
      applySnapshot(self, {});
    },
  }));

type QuizStoreType = Instance<typeof QuizStoreModel>;

export interface QuizStore extends QuizStoreType {}
