import React from 'react';

import styles from './contact-us-link.module.scss';

export interface Props {
  subject?: string;
}

const ContactUsLink = ({ subject }: Props) => (
  <a
    className={styles.container}
    href={`mailto:jenny@faceyoga-ar.com${
      subject == null ? '' : `?subject=${encodeURIComponent(subject)}`
    }`}>
    contact us
  </a>
);

export default ContactUsLink;
