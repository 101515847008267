import { useMutation } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { retry } from 'src/utils';

import { postPinterestEvent } from '@features/Analytics/api/pinterest';
import { TpPinterestParameters } from '@features/Analytics/types';

export const usePinterestEvent = () => {
  const { mutateAsync: postEvent } = useMutation({
    mutationFn: postPinterestEvent,
  });

  const conditionCheckFunction = useCallback(
    async () =>
      // @ts-ignore
      window?.pintrk != null,
    [],
  );

  const fetchClientEventWithRetry = useCallback(
    async ({ eventName, options }: TpPinterestParameters) => {
      const eventId = `${eventName}.${Math.floor(Math.random() * 100_000_000)}`;
      const actionFunction = async () => {
        // @ts-ignore
        window?.pintrk?.('track', eventName, { event_id: eventId, ...options });
      };

      await retry({
        conditionCheckFunction,
        actionFunction,
        retryDelay: 300,
      });

      await postEvent({
        eventName,
        eventId,
        options,
      });
    },
    [conditionCheckFunction, postEvent],
  );

  return useMemo(
    () => ({
      clientEvent: ({ eventName, options }: TpPinterestParameters) => {
        fetchClientEventWithRetry({
          eventName,
          options,
        });
      },
    }),
    [fetchClientEventWithRetry],
  );
};
