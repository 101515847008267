import Cookies from 'js-cookie';

import { post } from 'src/api/api';
import { ENDPOINT_MICROSERVICE } from 'src/api/payments';

const TEST_EVENT_CODE = process.env.NEXT_PUBLIC_TEST_EVENTS_CODE as string;

export type TpOrigin = 'eclipse';

const pixelVariants = {
  '530055452219000': 'eclipse',
};

type TpEventName =
  | 'AddToCart'
  | 'AddPaymentInfo'
  | 'ViewContent'
  | 'CompleteRegistration'
  | 'InitiateCheckout'
  | 'Purchase';

type TpPostPath =
  | 'event_start_onboard'
  | 'event_completed_registration'
  | 'event_user_init_checkout'
  | 'event_add_to_cart'
  | 'event_add_payment_info'
  | 'event_purchase';

type TpPostOptions = {
  email?: string;
  subscription_id?: string | number;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  gender?: string;
  currency?: string;
};

type TpFacebookEvent = {
  eventName: TpEventName;
  postPath: TpPostPath;
  options?: { [key: string]: any };
  eventId?: string;
  postOptions?: TpPostOptions;
  sendS2Sevent?: boolean;
};

export const fbAnalytics = (props: TpFacebookEvent) => {
  const {
    eventName,
    eventId: eventIdProperty,
    options = {},
    postPath,
    postOptions = {},
    sendS2Sevent = true,
  } = props;
  const externalId = Cookies.get('extId');
  const fbp = Cookies.get('_fbp');
  const fbc = Cookies.get('_fbc');
  const randomInt = Math.floor(Math.random() * 100_000_000);

  const eventId = eventIdProperty || `${eventName}.${randomInt}`;

  const sendAnalyticRequest = async () => {
    try {
      await post({
        endpoint: ENDPOINT_MICROSERVICE,
        path: postPath,
        body: {
          fbp,
          fbc,
          event_id: eventId,
          external_id: externalId,
          test_event_code: TEST_EVENT_CODE,
          origin: 'eclipse',
          ...postOptions,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const waitForFbq = (callback: () => void) => {
    // @ts-ignore
    if (window?.fbq === undefined) {
      setTimeout(() => {
        waitForFbq(callback);
      }, 500);
    } else {
      callback();
    }
  };

  if (eventName === 'Purchase') {
    waitForFbq(() => {
      // @ts-ignore
      window?.fbq?.('track', eventName, options, {
        eventID: eventId,
        extern_id: externalId,
      });
    });
  } else {
    // @ts-ignore
    window?.fbq?.('track', eventName, options, {
      eventID: eventId,
      extern_id: externalId,
    });
  }

  (fbp || externalId) &&
    process.env.NEXT_PUBLIC_ENV === 'production' &&
    sendS2Sevent &&
    sendAnalyticRequest().then((r) => r);
};
