import { Instance, SnapshotOut, applySnapshot, types } from 'mobx-state-tree';
import { flow } from 'mobx-state-tree';

import * as auth from '../../api/auth';
import { UserModel, UserSnapshot } from '../user/user';

export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    user: types.maybe(types.union(UserModel, types.null)),
    auth_token: types.maybe(types.string),
    access_code: types.maybe(types.string),
    consentCookie: false,
    wasDataSent: false,
    googlePseudoID: types.maybe(types.string),
    loginedByWinback: false,
    variant: types.maybe(types.string),
  })

  .actions((self) => ({
    setUser: (user: UserSnapshot | null) => {
      if (!self.user || user === null) {
        self.user = user;
      } else {
        applySnapshot(self.user, user);
      }
    },
    setAuthToken: (token: string) => {
      self.auth_token = token;
    },
    setAccessCode: (code: string) => {
      self.access_code = code;
    },
    setConsentCookie: (value: boolean) => {
      self.consentCookie = value;
    },
    setWasDataSent: (value: boolean) => {
      self.wasDataSent = value;
    },
    setGooglePseudoID: (code: string) => {
      self.googlePseudoID = code;
    },
    setLoginedByWinback: (value: boolean) => {
      self.loginedByWinback = value;
    },
    setVariant: (variant: string | undefined) => {
      self.variant = variant;
    },
  }))
  .actions((self) => ({
    fetchUser: flow(function* ({
      token,
      abortSignal,
    }: {
      token?: string;
      abortSignal?: AbortSignal;
    }) {
      const user = yield auth.fetchUser({ token, abortSignal });
      self.setUser(user);
    }),
    register: flow(function* ({
      email,
      birthday,
      firstname,
      abortSignal,
    }: {
      email: string;
      birthday: string;
      firstname?: string;
      abortSignal?: AbortSignal;
    }) {
      const user = yield auth.register({
        email,
        birthday,
        firstname,
        abortSignal,
        variant: self.variant,
      });
      self.setAuthToken(user.token);
      self.setAccessCode(user.accessCode);
    }),
    logOut: flow(function* (token: string) {
      yield auth.logOut(token);
      self.setUser(null);
      self.setAuthToken('');
      self.setAccessCode('');
      self.setWasDataSent(false);
      self.setGooglePseudoID('');
      self.setLoginedByWinback(false);
    }),
    loginBySafariAuthToken: flow(function* ({
      safariAuthToken,
    }: {
      safariAuthToken: string;
    }) {
      const user = yield auth.loginBySafariAuthToken({ safariAuthToken });
      self.setUser(user);
      self.setAccessCode(user.accessCode);
      self.setAuthToken(user.token);
    }),
    loginByEmailToken: flow(function* ({
      emailToken,
      abortSignal,
    }: {
      emailToken: string;
      abortSignal?: AbortSignal;
    }) {
      const user = yield auth.loginWithEmailToken({ emailToken, abortSignal });
      self.setUser(user);
      self.setAccessCode(user.accessCode);
      self.setAuthToken(user.token);
    }),
  }))
  .views((self) => ({
    get isStripeVariant() {
      return (
        self.variant === 'variant16' ||
        self.variant === 'variant16b' ||
        self.variant === 'variant16v' ||
        self.variant === 'TW16' ||
        self.variant === 'PT16' ||
        self.variant === 'google16' ||
        self.variant === 'tiktok16' ||
        self.variant === 'default' ||
        self.variant === undefined
      );
    },
    get isBraintreeVariant() {
      return (
        self.variant === 'variant15' ||
        self.variant === 'variant17' ||
        self.variant === 'variantPT' ||
        self.variant === 'variantTW' ||
        self.variant === 'google' ||
        self.variant === 'variantTikTok'
      );
    },
  }));

type AuthStoreType = Instance<typeof AuthStoreModel>;

export interface AuthStore extends AuthStoreType {}

type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;

export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
