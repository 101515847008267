import Cookies from 'js-cookie';

import { axiosMicroservice } from 'src/api/axios';
import { TEST_EVENT_CODE } from 'src/constants/api';
import { getDataFromSessionStorage } from 'src/utils';

import type { TpFacebookPostParameters } from '@features/Analytics/types/facebook';

export const postFacebookEvent = async ({
  externalId,
  eventId,
  postPath,
  fbp,
  options,
}: TpFacebookPostParameters & { fbp: string }) => {
  const fbc = Cookies.get('_fbc');

  const pixelVariants: Record<string, string> = {
    '530055452219000': 'eclipse',
  };

  const currentPixelId = getDataFromSessionStorage({
    store: 'analyticsStore',
    key: 'fbPixelId',
  });

  try {
    const { data } = await axiosMicroservice.post(postPath, {
      fbp,
      fbc,
      event_id: eventId,
      external_id: externalId,
      test_event_code: TEST_EVENT_CODE,
      origin: pixelVariants[currentPixelId || '530055452219000'],
      ...options,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
