import Cookies from 'js-cookie';
import { observer } from 'mobx-react-lite';
import Script from 'next/script';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { GA_ID } from '../../constants';

export const GoogleAnalytics = observer(() => {
  const {
    authStore: { consentCookie },
  } = useStores();
  const consent = Cookies.get('siteConsent') || consentCookie;

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <Script id="gtag">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){ dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('consent', 'default', {
            'ad_storage': 'denied'
          });
          gtag('config', ${JSON.stringify(GA_ID)}, {
            page_path: window.location.pathname
          });`}
      </Script>

      {consent && (
        <Script id="consent-update">
          {`gtag('consent', 'update', {
            'ad_storage': 'granted'
          });`}
        </Script>
      )}
    </>
  );
});
