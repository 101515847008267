export type TpVariants =
  | 'variant15'
  | 'variant17'
  | 'variantPT'
  | 'variantTW'
  | 'google'
  | 'variantTikTok';

export type TpStripeVariants =
  | 'default'
  | 'variant16'
  | 'variant16b'
  | 'variant16v'
  | 'TW16'
  | 'PT16'
  | 'google16'
  | 'tiktok16';

export type TpSource =
  | 'facebook'
  | 'twitter'
  | 'google'
  | 'tiktok'
  | 'pinterest';
export type TpPaymentSystem = 'braintree' | 'stripe';

export type TpVariantObject = {
  variant_name: TpVariants | TpStripeVariants;
  payment_system: TpPaymentSystem;
  source: TpSource;
};

export const ALL_VARIANTS: Array<TpVariantObject> = [
  {
    variant_name: 'variant15',
    payment_system: 'braintree',
    source: 'facebook',
  },
  {
    variant_name: 'variantPT',
    payment_system: 'braintree',
    source: 'pinterest',
  },
  {
    variant_name: 'variantTW',
    payment_system: 'braintree',
    source: 'twitter',
  },
  {
    variant_name: 'google',
    payment_system: 'braintree',
    source: 'google',
  },
  {
    variant_name: 'variantTikTok',
    payment_system: 'braintree',
    source: 'tiktok',
  },
  {
    variant_name: 'variant17',
    payment_system: 'braintree',
    source: 'facebook',
  },
  {
    variant_name: 'variant16',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant16b',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'variant16v',
    payment_system: 'stripe',
    source: 'facebook',
  },
  {
    variant_name: 'TW16',
    payment_system: 'stripe',
    source: 'twitter',
  },
  {
    variant_name: 'PT16',
    payment_system: 'stripe',
    source: 'pinterest',
  },
  {
    variant_name: 'google16',
    payment_system: 'stripe',
    source: 'google',
  },
  {
    variant_name: 'tiktok16',
    payment_system: 'stripe',
    source: 'tiktok',
  },
  {
    variant_name: 'default',
    payment_system: 'stripe',
    source: 'facebook',
  },
];
