import { GA_ID } from '../constants';

export const googlePageView = (url: string) => {
  // @ts-ignore
  window.gtag?.('config', GA_ID, {
    page_path: url,
  });
};

export const googleUniversalEvent = ({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category?: string;
  label?: string;
  value?: number;
}) => {
  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const googleAnalyticsEvent = ({
  eventName,
  properties,
}: {
  eventName: string;
  properties?: { [key: string]: string | number | boolean | null };
}) => {
  window.gtag?.('event', eventName, properties);
};

export const googleSetUserProperties = ({
  properties,
}: {
  properties: { [key: string]: string | number | boolean | null };
}) => {
  window.gtag?.('set', 'user_properties', properties);
};
