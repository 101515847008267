import classNames from 'classnames';
import Head from 'next/head';
import React, { ComponentProps, ReactNode } from 'react';

import {
  IosDeviceType,
  getIosDeviceType,
} from 'src/utils/get-mobile-operating-system';

import ModalContainer from '../modal-container/modal-container';
import styles from './alert.module.scss';

export interface Props extends ComponentProps<typeof ModalContainer> {
  canClose?: boolean;
  image?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
}

const Alert = ({
  className,
  backdropClassName,
  dialogClassName,
  canClose = true,
  image,
  title,
  description,
  footer,
  children,
  ...props
}: Props) => {
  const { show } = props;
  const iosDeviceType = getIosDeviceType();
  const isIosHandheld =
    iosDeviceType === IosDeviceType.IPOD ||
    iosDeviceType === IosDeviceType.IPHONE;

  return (
    <ModalContainer
      backdropClassName={classNames(styles.backdrop, backdropClassName)}
      className={classNames(styles.container, className)}
      dialogClassName={classNames(styles.modal, dialogClassName)}
      backdrop={canClose ? undefined : 'static'}
      {...props}>
      {/*
        Making the status bar on iOS 15 appear to be covered by the backdrop.
        Doing this for iPhones only because iPads and most Android browsers
        have their address bars at the top. Earlier versions of iOS Safari
        do not support `theme-color` and thus are unaffected.
      */}
      {show && isIosHandheld && (
        <Head>
          <meta name="theme-color" content="#B3B3B3" />
        </Head>
      )}
      {image && <div className={styles.imageContainer}>{image}</div>}
      {title && <h2 className={styles.title}>{title}</h2>}
      {description && <p className={styles.description}>{description}</p>}
      {children}
      {footer}
    </ModalContainer>
  );
};

export default Alert;
